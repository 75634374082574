<template>
  <div>
    <!-- 直播界面入口 -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'LiveEntry',
  data() {
    return {
      url: ''
    }
  },
  computed: {
    ...mapState(['userInfo', 'isLogin']),
    Aid() {
      return this.$route.query.Aid;
    }
  },
  created() {
    console.log(this.Aid)
    this.getLiveDocking();
  },
  methods: {
    getLiveDocking() {
      if(this.Aid) {
        this.$axios.get('/Api/Api/Web/Live/LiveDocking?artId=' + this.Aid).then(res => {
          if (res.RetCode == '10000') {
            location.href = res.Data;
          } else if (res.RetCode == '10005') {
            this.$toast('请联系E起爱缝合运营人员（徐佳玲，15306128368，jialing@tv-conf.com)完成直播上架');
          } else {
            this.$toast(res.RetMsg);
          }
        })
      } else {
        this.$toast('数据获取异常，请联系管理员！');
      }
    }
    // getLiveUrl() {
    //   if(this.userInfo.Id && this.uri) {
    //     this.$axios.post('/Api/Api/Web/Live/LiveDockingByChannel', {
    //       CustomerID: this.userInfo.Id,
    //       Url: this.url
    //     }).then(res => {
    //       if (res.RetCode == '10000') {
    //         // location.href = res.Data;
    //       } else {
    //         this.$toast(res.RetMsg);
    //       }
    //     })
    //   } else {
    //     this.$toast('数据获取异常，请联系管理员！');
    //   }
      
    // }
  }
}
</script>